import stylo_xr_thumbnail from './images/thumbnails/stylo_xr.png'
import React, { useState } from 'react';
const Home = () => {

  const scrollDown = () => {
    window.scrollTo({
        top: 750,
        behavior: "smooth"
    });
};

  function readMore(linkRef, text) {
    return (
      <a className="read-more" href={linkRef}>
        <div className="read-more-text">{text}</div>
        <svg className="read-more-arrow" xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16" fill="none">
          <path d="M16.5 -6.99382e-07L15.09 1.41L20.67 7L0.5 7L0.5 9L20.67 9L15.08 14.58L16.5 16L24.5 8L16.5 -6.99382e-07Z" fill="#FBD784"/>
        </svg>
      </a>
    )
  }

  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => {
    setIsVisible(false);
  }

  return ( 
    <div className="landing-page">

      {isVisible &&
        <div className="splash">
          <div className="splash-exit" onClick={handleClose}>X</div>
          <a className="splash-click" href={"/toolbox/stylo-xr"}>
            <div className="splash-text">Check out our new visualization of stylometry: StyloXR</div>
            <div className="splash-sub">
              <div className="splash-sub-text">take me there</div>
              <svg className="splash-arrow" xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16" fill="none">
                <path d="M16.5 -6.99382e-07L15.09 1.41L20.67 7L0.5 7L0.5 9L20.67 9L15.08 14.58L16.5 16L24.5 8L16.5 -6.99382e-07Z" fill="#000"/>
              </svg>
            </div>
          </a>
        </div>
      }


      <div className="bg-img-top" />
      <div className="bg-top-gradient-1"/>
      <div className="bg-top-gradient-2"/>
      <div className="bg-top-gradient-3"/>
      <div className="bg-top-gradient-4"/>
      <div className="gradient-blur-top"/>

      <div className="bg-img-bottom" />
      <div className="bg-bottom-gradient-1"/>
      <div className="bg-bottom-gradient-2"/>
      <div className="gradient-blur-bottom"/>

      {/* Everything in this div currently hidden using CSS*/}
      <div className="slider">
        <svg className="slider-bar" xmlns="http://www.w3.org/2000/svg" width="3" height="240" viewBox="0 0 3 240" fill="none">
          <rect opacity="0.5" width="3" height="240" fill="white"/>
          <rect width="3" height="60" fill="white"/>
        </svg>
        <div className="slider-text">
          <div className="slider-text-content">Start</div>
          <div className="slider-text-content">01</div>
          <div className="slider-text-content">02</div>
          <div className="slider-text-content">03</div>
        </div>
      </div>


      <h1 className="home-title">
        <div className="home-title-header">
          <div className="home-title-header-line"/>
          <div className="home-title-header-text">Embrace faith with scientific clarity</div>
        </div>
        <div className="home-title-text">
          Affirm your faith in the Book of Mormon with scientific evidence
        </div>
        <div className="home-title-scroller" onClick={scrollDown}>
          <div className="home-title-scroller-text">scroll down to see more</div>
          <svg className="home-title-scroller-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="none">
            <path d="M16 16L14.59 14.59L9 20.17V0H7V20.17L1.42 14.58L0 16L8 24L16 16Z" fill="white"/>
          </svg>
        </div>

      </h1>

      <div id="startHere" className="home-content">

        <div className="home-content-frame">
          <div className="big-number home-01">01</div>
          <div className="home-text first-frame-offset">
            <div className="tagline-01">
              <div className="tagline-line"/>
              <div className="tagline-text">toolbox</div>
            </div>
            <div className="home-text-title">Study the Book of Mormon like never before</div>
            <div className="home-text-content">Book of Mormon Explorer is based on a computational linguistic study of different speakers’ words in the Book of Mormon. Click here to learn more about the data-visualization tools we provide and examine the data yourself.</div>
             {readMore("/toolbox", "Explore the data")}
          </div>
          <img className="home-img-01"/>
        </div>

        <div className="home-content-frame">
          <div className="big-number home-02">02</div>
          <img className="home-img-02"/>
          <div className="home-text second-frame-offset">
            <div className="tagline-02">
              <div className="tagline-line"/>
              <div className="tagline-text">cameos</div>
            </div>
            <div className="home-text-title">Delve into the distinct styles of speakers in the Book of Mormon</div>
            <div className="home-text-content">Read our analyses of the speakers' words, consider recurring concepts in a different light, and discover linguistic links between speakers. We call these analyses "cameos." Click here to learn more about stylometrics and the stylistic diversity of the speakers in the Book of Mormon.</div>
            {readMore("/cameos", "Read more about the speakers")}
          </div>
        </div>

        <div className="home-content-frame">
          <div className="big-number home-03">03</div>
          <div className="home-text third-frame-offset">
            <div className="tagline-03">
              <div className="tagline-line"/>
              <div className="tagline-text">words matter</div>
            </div>
            <div className="home-text-title">Learn more about Book of Mormon Explorer</div>
            <div className="home-text-content">Critics of the Prophet Joseph Smith who claim him the author of a fictional book with imagined characters would find it difficult to dispute what you are about to discover. The results of the Book of Mormon Explorer project suggest that the Book of Mormon was written by multiple authors, each with a unique voice. Learn more about the methods and techniques that went into this investigation of the Book of Mormon.</div>
            {readMore("/about", "Learn more about Book of Mormon Explorer")}
          </div>
          <img className="home-img-03"/>
        </div>

        <div className="highlight-section">
          <div className="highlight-section-frame">
            <div className="highlight-title">What is the Book of Mormon?</div>
            <div className="highlight-paragraph">If you’re new to the Book of Mormon, please click below to learn more about this book and the Church of Jesus Christ of Latter-day Saints. This tool is helpful once you’ve read the Book of Mormon and are familiar with its speakers. Click below to visit the Church's official website.</div>
            {readMore("https://www.churchofjesuschrist.org/comeuntochrist/believe/book-of-mormon", "Learn more about the Book of Mormon")} {/**/}
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Home;
