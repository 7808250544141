import timeline_thumbnail from './images/thumbnails/timeline.png'
import connections_thumbnail from './images/thumbnails/connections.png'
import network_diagram_thumbnail from './images/thumbnails/speakers.png'
import word_bubbles_thumbnail from './images/thumbnails/word_bubbles.png'
import bom_semanticmap_thumbnail from './images/thumbnails/bomsemanticmap.png'
import bible_semanticmap_thumbnail from './images/thumbnails/biblesemanticmap.png'
import similar_verse_thumbnail from './images/thumbnails/similarverse.png'
import social_network_thumbnail from './images/thumbnails/social_network.png'
import stylo_xr_thumbnail from './images/thumbnails/stylo_xr.png'

import placeholder from './images/SC-Book-Of-Mormon-Central-Favicon-Black-32px.png'

const Toolbox = () => {
  return ( 
    <div className='all-list-container'>
      <h1>Toolbox</h1>
      <div className="blurb">These tools visualize the relationship between the speakers, characters, and texts of the Book of Mormon. Using semantic vector embeddings, corpus-based methods, and carefully compiled speaker data, the Book of Mormon is quanitatively shown to be a compendium of diverse rhetorical situations and personalities. Specific applications of our toolbox to each speaker and to many theological concepts are provided in our cameos. Below is a brief video walkthrough of the structure of our toolbox.</div>
      <iframe className="youtube-iframe" width="560" height="315" src="https://www.youtube.com/embed/J5hryvp5TTo?si=xcyOY0bTkQoJxDl1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <div className="content-toolbox">
        <a className='toolbox-container' href={ '/toolbox/timeline' }>
          <div className="toolbox-item">
            <img src={ timeline_thumbnail } alt="" />
            <h4 className='toolbox-item-label'>Timeline</h4>
          </div>
        </a>
        <a className='toolbox-container' href={ '/toolbox/word-bubbles' }>
          <div className="toolbox-item">
            <img src={ word_bubbles_thumbnail } alt="" />
            <h4 className='toolbox-item-label'>Word Bubbles</h4>
          </div>
        </a>
        <a className='toolbox-container' href={ '/toolbox/connections' }>
          <div className="toolbox-item">
            <img src={ connections_thumbnail } alt="" />
            <h4 className='toolbox-item-label'>Connections</h4>
          </div>
        </a>
        <a className='toolbox-container' href={ '/toolbox/network-diagram' }>
          <div className="toolbox-item">
            <img src={ network_diagram_thumbnail } alt="" />
            <h4 className='toolbox-item-label'>Network Diagram</h4>
          </div>
        </a>

        <a className='toolbox-container' href={ '/toolbox/BOM-semantic-map' }>
          <div className="toolbox-item">
            <img src={ bom_semanticmap_thumbnail } alt=""/>
            <h4 className='toolbox-item-label'>Book of Mormon Semantic Map</h4>
          </div>
        </a>
        <a className='toolbox-container' style={{display:'none'}}href={ '/toolbox/Bible-semantic-map' }>
          <div className="toolbox-item">
            <img src={ bible_semanticmap_thumbnail } alt=""/>
            <h4 className='toolbox-item-label'>Holy Bible Semantic Map</h4>
          </div>
        </a>
        <a className='toolbox-container' href={ '/toolbox/similar-verse' }>
          <div className="toolbox-item">
            <img src={ similar_verse_thumbnail } alt=""/>
            <h4 className='toolbox-item-label'>Similar Verse Finder</h4>
          </div>
        </a>
        <a className='toolbox-container' href={ '/toolbox/social-network' }>
          <div className="toolbox-item">
            <img src={ social_network_thumbnail  } alt=""/>
            <h4 className='toolbox-item-label'>Social Network</h4>
          </div>
        </a> 
        <a className='toolbox-container' href={ '/toolbox/stylo-xr' }>
          <div className="toolbox-item">
            <img src={ stylo_xr_thumbnail } alt=""/>
            <h4 className='toolbox-item-label'>Stylo XR</h4>
          </div>
        </a> 
      </div>
      <br/>
    </div>
   );
}
 
export default Toolbox;

