import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Navbar from './Navbar';
import Footer from './Footer';

import Home from './Home';
import NotFound from './NotFound';
import About from './About';

import Toolbox from './Toolbox';
import Timeline from './Timeline';
import WordBubbles from './WordBubbles';
import Connections from './Connections'
import NetworkDiagram from './NetworkDiagram';
import BOMSemanticMap from './BOMSemanticMap';
import SocialNetwork from './SocialNetwork';
import SimilarVerse from './SimilarVerse';
import BibleSemanticMap from './BibleSemanticMap';
import StyloXR from './StyloXR';

import Feedback from './Feedback';

import ArticlesAll from './ArticlesAll';
import Article from './Article';


function App() {

  ReactGA.initialize('G-T7QKVYZF7P'); 
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

  return (
    <Router>
      <Navbar></Navbar>
      <Footer></Footer>
      <Routes>
        <Route path="/" element={<Home/>}></Route>

        <Route path="/about" element={<About/>}></Route>

        <Route path="/feedback" element={<Feedback/>}></Route>

        <Route path="/cameos"  element={<ArticlesAll/>}></Route>
        <Route path="/cameos/:cameo_type/:article_tag"  element={<Article/>}></Route>

        <Route path="/toolbox" element={<Toolbox/>}></Route>
        <Route path="/toolbox/timeline" element={<Timeline/>}></Route>
        <Route path="/toolbox/word-bubbles" element={<WordBubbles/>}></Route>
        <Route path="/toolbox/connections" element={<Connections/>}></Route>
        <Route path="/toolbox/network-diagram" element={<NetworkDiagram/>}></Route>
        <Route path="/toolbox/BOM-semantic-map" element={<BOMSemanticMap/>}></Route>
        <Route path="/toolbox/Bible-semantic-map" element={<BibleSemanticMap/>}></Route>
        <Route path="/toolbox/similar-verse" element={<SimilarVerse/>}></Route>
        <Route path="/toolbox/social-network" element={<SocialNetwork/>}></Route>
        <Route path="/toolbox/stylo-xr" element={<StyloXR/>}></Route>

        <Route path="/" element={<Home></Home>}></Route>
        <Route path="*" element={<NotFound/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
