const StyloXR = () => {
    let parameters = new URLSearchParams(window.location.search);
  
    let reference = ""
    if (parameters.get("reference")) {
      reference = parameters.get("reference")
    }
  
    return ( 
      <div className="widget-container"> 
        <h1>3D Stylometric Analysis</h1>
        <div>
            <div className="subcameo-subheading">Instructions</div> 
            <div className="blurb">
                At the bottom of the below display, press the "play" button to start a walkthrough of the widget that goes over the findings and conclusions of the study. Hover over points to see more information. Click and drag to rotate and middle-mouse drag to pan. 
                <br/><br/>
                Please note that as this demo uses the latest web technologies, if you run into any issues, please try using Google Chrome or another Chromium-based browser for the best experience.
            </div> 
        </div>
        
        <iframe src={"https://bomextest.org/Widgets/StyloXR/index.html"} style={{height: "800px", width: "120%", border:"none"}}></iframe>

        <div>
            <div className="subcameo-subheading">Video Transcript</div>
            <div className="blurb">
                Welcome to Book of Mormon Explorer, a collection of resources to help us explore the teachings and testimonies of the ancient prophets in the Book of Mormon. In this demonstration, we seek to answer the question: "Is there evidence that the speakers in the Book of Mormon were different people who spoke with their own voices?" Through stylometric analysis, we can help answer that question. We can see if there are different voices, or if the text was written by only one person.
                <br/><br/>
                Stylometry examines texts using statistical tools to determine a speaker's style of expression. That is where the name comes from: "stylometry" is "measuring style." In a stylometric analysis, we look at how a particular speaker uses grammatical words, also called function words. Because there is a closed set of function words, they are particularly useful for describing a speaking style, and distinguishing one speaker from another speaker, regardless of what they might be speaking about. 
                <br/><br/>
                A team of linguists, statisticians, animators, and computer programmers applied stylometric analysis to study the speakers in the Book of Mormon. They have displayed the results in a 3D visualization so that we can see and experience the voices in the Book of Mormon. Let's step into the world of Book of Mormon voices. The Liahona will be our guide.
                <br/><br/>
                You are looking at a comparison of the voices in the Book of Mormon to the voices in eight novels written in the 19th century, about the time that the Book of Mormon was translated. Using 250 function words and forming 250 dimensions to describe a speaker's style, we can statistically project that data down into a two dimensional or three dimensional space. 
                <br/><br/>
                The axes you see are the three dimensions that separate the voices of the speakers in the Book of Mormon and the voices in the novels written by four of the greatest novelists of Joseph Smith's time. There are two novels from each of two English novelists and two American novelists. Each axis is called a discriminate function, since it "discriminates" or separates the data points. This first axis separates the Book of Mormon's speakers from the speakers in the novels. This axis separates the speakers of the 19th century novels from one another, and this axis separates the speakers in the Book of Mormon from each other. 
                <br/><br/>
                Notice the separation between the groups of spheres. These spheres represent the characters of the 19th century novels. There's one sphere for each character. The size of a sphere is proportional to the number of words spoken by that character. These spheres represent the voices of characters created by Jane Austen in Pride and Prejudice, and in Sense and Sensibility. These spheres represent the words spoken by the characters in Charles Dickens's novels, Great Expectations in Oliver Twist. These spheres are the speakers in James Fenimore Cooper's novels, the Last of the Mohicans and Deerslayer. And these spheres are the characters in Mark Twain's novels, Adventures of Tom Sawyer and Adventures of Huckleberry Finn. You can see that the characters group together by author, which is not surprising, since they were created by the same mind. But you can also see that these authors have created different speaking styles for each character. For instance, in Jane Austen's Pride and Prejudice, Elizabeth Bennet speaks differently than Mr. Darcy, and in Mark Twain's novels, Tom Sawyer speaks differently than his aunt Polly. One measure of literary skill is in an author's ability to create characters with different speaking styles. And you can see that, despite each author having their own particular style, they have also managed to create a breadth of different voices for their novels. 
                <br/><br/>
                Notice this axis here, representing the degree of greatest separation among all the spheres. Notice that the Book of Mormon is very different stylistically from the novels. The spiritual nature and speaking style present in the words of the Book of Mormon are very different from the style of speaking common to novels from the same time period. Within the text of the Book of Mormon there are 82 speakers, of whom 28 have enough words for us to distinguish their individual speaking styles using stylometry. Notice the breadth of styles displayed by the 28 speakers in the Book of Mormon, from Abinadi to Zeniff. Here, the largest sphere is Mormon. He speaks the most in the Book of Mormon. In fact, 1/3 of the words in the text are spoken by him, which is why the book is named after him. He speaks about 97,000 words. Nephi uses about 28,000 words, so his sphere is the second largest, followed by Alma the Younger, with 21,000 words. You can see that the speakers in the Book of Mormon separate distinctly from each other. The further two spheres are from each other, the greater the difference in speaking styles of those two speakers. Clearly, the speakers in the Book of Mormon each have their own distinct voice.
                <br/><br/>
                Most strikingly, there is far more stylistic variation among the Book of Mormon speakers than among all the characters created by four of the greatest literary geniuses of the 19th century combined. In fact, there was 50% more variation among the Book of Mormon speakers. If Joseph Smith alone, or even with the help of friends, created the Book of Mormon, he would have needed greater literary skill than Jane Austen, Charles Dickens, James Fennimore Cooper, and Mark Twain combined. This is highly unlikely for a farm boy with little education on the American frontier in the year 1829, a man whose wife said he could not write nor dictate a coherent well worded letter. But if the Book a Mormon is, as Joseph Smith claimed, the compiled voices of ancient prophets translated by the gift and power of God, then we can see why the breadth of voices in its pages exceeds the work of some of the most renowned authors of the nineteenth century. And from each distinct voice in the Book of Mormon, and their prophetic teachings, we can learn eternal gospel truths. We can hear their testimonies of the divinity of Jesus Christ.
            </div>
            <br/>
        </div>
      </div>
      );
    }
     
    export default StyloXR;
    
  