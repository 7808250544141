import { Link } from 'react-router-dom';
import { useState } from 'react';
import NavBarExpansion from './NavBarExpansion';
import navHamburgerMenu from './images/hamburger-menu.svg';

const Navbar = () => {
  const reactiveHoverItems = [
    'navbar-about-header',
    'navbar-cameos-header',
    'navbar-toolbox-header', 
    'nav-hamburger-container'
  ]
  let reactiveHoverStatesObject = {};
  const [hoverStates, setHoverStates] = useState(reactiveHoverStatesObject);

  const toolboxExpandableItems = [
    'Timeline',
    'Word Bubbles',
    'Connections',
    'Network Diagram',
    'Book of Mormon Semantic Map',
    'Similar Verse Finder',
    'Social Network',
    'Stylo XR'
   ]; 

  const toolboxExpandableLinks = [
    '/toolbox/timeline',
    '/toolbox/word-bubbles',
    '/toolbox/connections',
    '/toolbox/network-diagram',
    '/toolbox/BOM-semantic-map',
    '/toolbox/similar-verse',
    '/toolbox/social-network',
    '/toolbox/stylo-xr'
  ];

  const hamburgerExpandableItems = [
    'Home',
    'About',
    'Cameos',
    'Toolbox',
    'Contact'
  ];

  const hamburgerExpandableLinks = [
    '/',
    '/about',
    '/cameos',
    '/toolbox',
    '/feedback'
  ];
  
  const toggleIsHovering = (event) => {
    const adjHoverStates = {...hoverStates};
    console.log('EVENT.CURRENTTARGET.ID: ' + event.currentTarget.id);
    adjHoverStates[event.currentTarget.id] = !adjHoverStates[event.currentTarget.id];
    let filteredHovering = {};
    Object.entries(adjHoverStates).forEach(([key, value]) => key === event.currentTarget.id ? filteredHovering[key] = value : filteredHovering[key] = false);
    setHoverStates(filteredHovering);
    console.log(filteredHovering);
  }

  return (  
    <nav className="navbar">
      <div className="nav-title">
        <Link className='site-title' to="/">
            BOOK OF MORMON EXPLORER
        </Link>
      </div>

      <div className="nav-links">
        <Link to="/toolbox" id={reactiveHoverItems[1]} className='nav-header' onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
          Toolbox
          <span className="down-carrot"> &#9660;</span>
          {hoverStates[reactiveHoverItems[1]] && (
          <NavBarExpansion className='navbar-expanded-menu' expandableItems={toolboxExpandableItems} expandableLinks={toolboxExpandableLinks} ></NavBarExpansion>        
        )}
        </Link>

        <Link to="/cameos" className='nav-header' onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
          Cameos
        </Link>

        <Link to="/about" className='nav-header'>About</Link>


        <Link to="/Feedback" className='nav-header'>Contact</Link>

      </div>
      <div id={reactiveHoverItems[2]} className='nav-hamburger-container'  onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
        <svg className='nav-hamburger-menu' viewBox='0 -13 100 100'>
          <image className='hamburger-svg-image' width='100' height='73' viewbox='0 0 100 100' href={navHamburgerMenu}/>
        </svg>
        {hoverStates[reactiveHoverItems[2]] && (
            <NavBarExpansion className='navbar-expanded-menu' expandableItems={hamburgerExpandableItems} expandableLinks={hamburgerExpandableLinks} style={{position: 'absolute', width: '14vw', height: 'auto', top: 'calc(max(min(36px, 3vw), 16px) + 2vw)', right: '0.5vw', zIndex: 9}}></NavBarExpansion>
          )}
      </div>
    </nav>
  );
}
 
export default Navbar;
